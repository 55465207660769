import { Injectable, inject, signal } from '@angular/core';
import { ApiHttpService } from './api-http.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, switchMap, tap } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { environment } from 'environments/environment';
// import { NotificationsService } from 'app/layout/common/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class ItmUserService {
  private readonly _msalService = inject(MsalService);
  private readonly httpClient = inject(ApiHttpService);
  // private readonly notificationsService = inject(NotificationsService);
  private readonly url_GetUser = 'core/user';

  constructor() {
    // console.log('ItmUserService - constructor')
    // const accessToken = localStorage.getItem('accessToken');
    // if (accessToken) {
      // this.setUserId(uniqueId);
      // this.decodeJwt_SetUser(accessToken);
      
      // this.notificationsService.getAll().subscribe(res => {
      //   // console.log(res)
      // });
    // }
  }

  userSig = signal<any | undefined>(undefined);
  user$ = toObservable(this.userSig)
    .pipe(
      filter(Boolean),
      map(m => {
        console.log('user$', m);
        
        return m;
      }),
      switchMap(x => this.httpClient.PostWithApiKey<any>(`${this.url_GetUser}`, this.userSig())
        .pipe(
          tap(x => {
            if (x.data == undefined) {
              this._msalService.logoutRedirect();
            }
          }),
          map(x => {
            return x.data.result
          }),
          //shareReplay(1)
        ))
    );
  // userSignal = toSignal(this.user$);

  // setUserId(userId: string) {
  //   this.userSig.set(userId);
  // }

  setUser(user: any) {
    console.log('setUser...', user);
    
    this.userSig.set(user);
  }

  getIdToken(): string {
    return localStorage.getItem('idToken');
  }
  
  getAccessTokenLocalStorage() {
    return localStorage.getItem('accessToken');
  }

  async getAccessToken() {
    try {
      // Assuming you're already signed in (interceptor likely has token)
      const account = await this._msalService.instance.getActiveAccount();
      // console.log('account', account);

      if (!account) {
        throw new Error("Unexpected: User not signed in"); // Handle unexpected case
      }

      ////console.log("Access token likely already available for account:", account.username);
      // Use the access token directly from the account object (optional)
      // const accessToken = account.accessToken;

      // Or get a new token silently if needed (interceptor might handle this)
      const silentRequest = {
        scopes: [environment.allApi_scopes], // Replace with the scopes your API requires
        account: account, // Specify the account to use for silent acquisition
      };
      ////const silentResult = await this.#msalService.acquireTokenSilent(silentRequest);
      ////console.log("Access token refreshed silently:", silentResult);
      // Use the refreshed access token for your logic here
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Handle interaction required error (e.g., consent refresh)
        console.error("Silent token acquisition failed (interaction required):", error);
        ////await this.#msalService.loginPopup(silentRequest);
      } else {
        // Handle other errors
        console.error("Unexpected error:", error);
      }
    }
  }

  
  decodeJwt_SetUser(): any {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        const [header, payload, signature] = accessToken.split('.');
        if (!payload) throw new Error('Invalid token');
        const jwtObject = JSON.parse(atob(payload)); // Decode Base64 URL to JSON
        // console.log(jwtObject)
        if(jwtObject){
          // const roles = jwtObject.wids.map(item => ({ id: item, name: '', description: '', isActive: true, }));

          const data = {
            "firstName": jwtObject.given_name,
            "lastName": jwtObject.family_name,
            "externalId": jwtObject.oid,
            "email": jwtObject.unique_name,
            // "displayName": jwtObject.unique_name,
            "roles": jwtObject.wids,
            "mobile": '015458752',
          }
          this.setUser(data);
        }
      }
    } catch (error) {
        console.error('Error decoding token:', error);
    }
  }
  
}



import {
    Component,
    AfterContentChecked,
    Input,
    effect,
    input,
    signal,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    inject,
} from "@angular/core";
import { environment } from "environments/environment";
import * as atlas from "azure-maps-control";
import { Router } from "@angular/router";
import * as mapconfig from "assets/mapconfig.json";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { filter, Subject, takeUntil, tap } from "rxjs";
import * as GeoJSON from 'geojson';

@Component({
    selector: "app-map",
    templateUrl: "./maps.component.html",
    styleUrls: ["./maps.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class MapComponent implements AfterViewInit  {
    keyS0: string = environment["AZURE_MAPS_KEY_S0"];
    keyS1: string = environment["AZURE_MAPS_KEY_S1"];
    map: atlas.Map;
    mapStyle: any = "satellite_road_labels";
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private readonly _changeDetectorRef = inject(ChangeDetectorRef);

    @Input("ContainerId") containerId: any = "mapContainer";
    actionData = input<any>();
    latitude = signal<any>(23.794831);
    longitude = signal<any>(90.414204);
    actionData$ = toObservable(this.actionData);
    actionEntity$ = this.actionData$.pipe(
        takeUntil(this._unsubscribeAll),
        filter(Boolean),
        filter(x => x != undefined),
        tap(x => {
        //   console.log(x);
          if(x){
            if(x.additional.latGeolocation){
                this.latitude.set(x.additional.latGeolocation)
            }
            if(x.additional.lonGeolocation){
                this.longitude.set(x.additional.lonGeolocation)
            }
            // this.map.events.add("ready", () => {
            //     this.map.setCamera({
            //         center: [this.longitude(), this.latitude()], // Ensure the center is valid
            //         zoom: mapconfig.zoom,
            //     });
            //     this.map.setStyle({
            //         view: mapconfig.view,
            //         style: this.mapStyle,
            //     });
            // });
            this._changeDetectorRef.detectChanges();
          }
        })
      );
      eactionEntitySig = toSignal(this.actionEntity$);

    // @HostListener("window:resize", ["$event"])
    // onResize(event) {
    //     this.adjustHeightAndWidth();
    // }

    constructor() {
        effect(() => {
            // console.log(this.latitude())
        })
    }

    ngOnInit() {
    }

    /**
     * */
    ngAfterContentChecked(): void {}

    /**
     *
     * */
    ngAfterViewInit() {
        // console.log('ngAfterViewInit')
        this.initializeMapConfiguration();
    }

    onGoogleMapClicked(e: any) {}

    clearDrawing() {};

    initializeMapConfiguration() {
        // console.log(this.latitude())
        // console.log(this.longitude())
        let s0 = this.keyS0;
        let s1 = this.keyS1;
        let boundingBox = [
            86.338953078, -44.6345972634, 182.569469029, -5.6681857235,
        ];
        this.map = new atlas.Map(this.containerId, {
            center: [this.longitude(), this.latitude()],
            zoom: mapconfig.zoom,
            // minZoom: mapconfig.zoom,
            // maxZoom: mapconfig.zoom,
            view: mapconfig.view,
            style: this.mapStyle,
            showLogo: mapconfig.showLogo,
            renderWorldCopies: mapconfig.renderWorldCopies,
            language: mapconfig.language,
            showBuildingModels: false,
            showTileBoundaries: false,
            // maxBounds: boundingBox,
            authOptions: {
                authType: atlas.AuthenticationType.subscriptionKey,
                subscriptionKey: this.keyS1,
            },
            transformRequest: function (url, resourceType) {
                if (url.includes("satellite")) {
                    url = url.replace(s0, s1);
                }
                return { url: url };
            },
            enableAccessibility: mapconfig.enableAccessibility,
            autoResize: mapconfig.autoResize,
            touchInteraction: mapconfig.touchInteraction,
            preserveDrawingBuffer: mapconfig.preserveDrawingBuffer,
            showFeedbackLink: false,
        });

        this.map.events.add("ready", () => {

            console.log(this.locations.map(loc => loc.coordinates))

            // this.removeUnwantedText();
            // this.map.setCamera({
            //     bounds: atlas.data.BoundingBox.fromPositions(this.locations.map(loc => loc.coordinates)),
            //     padding: 50
            // });
            this.map.setCamera({
                center: [this.longitude(), this.latitude()], // Ensure the center is valid
                // padding: 10,
                // bounds: [this.longitude(), this.latitude()],
                zoom: 17,
            });

            const datasource = new atlas.source.DataSource();
            
            this.addMarker();
            // this.map.controls.add(
            //     new atlas.control.StyleControl({
            //         mapStyles: mapconfig.mapStyles,
            //     }),
            //     { position: atlas.ControlPosition.TopRight }
            // );

            // //Create a zoom control.
            // this.map.controls.add(
            //     new atlas.control.ZoomControl({
            //         zoomDelta: mapconfig.zoomDelta,
            //         style: atlas.ControlStyle.light,
            //     }),
            //     { position: atlas.ControlPosition.TopRight }
            // );

            // //Create a pitch control and add it to the map.
            // this.map.controls.add(
            //     new atlas.control.PitchControl({
            //         pitchDegreesDelta: mapconfig.pitchDegreesDelta,
            //         style: atlas.ControlStyle.light,
            //     }),
            //     { position: atlas.ControlPosition.TopRight }
            // );

            // //Create a compass control and add it to the map.
            // this.map.controls.add(
            //     new atlas.control.CompassControl({
            //         rotationDegreesDelta: mapconfig.rotationDegreesDelta,
            //         style: atlas.ControlStyle.light,
            //     }),
            //     { position: atlas.ControlPosition.TopRight }
            // );
        });

        //drag end event
        this.map.events.add("dragend", function (e) {
            e.map.resize();
        });

        // Set style Data
        this.map.events.add("styledata", (e) => {
            var style = this.map.getStyle().style;
            this.mapStyle = style;
        });
    }

    locations = [
        { name: 'Tooth Fairy', coordinates: [90.4193, 23.8115] }, 
        { name: 'EverCare Hospital Dhaka', coordinates: [90.4207, 23.8095] },
        { name: 'International School Dhaka', coordinates: [90.4211, 23.8109] }
      ];

    addMarker(): void {
        const marker = new atlas.HtmlMarker({
            offset: [0, 0],
            anchor: "bottom",
            // pixelOffset: [0, -10],
            position: [this.longitude(), this.latitude()],
            // position: [90.418294, 23.796032],
            // position: [0, 0],
            color: 'red',
            //   htmlContent: `<div class="custom-marker">Marker at ${this.latitude()} ${this.longitude()}</div>`,
        //   popup: new atlas.Popup({
        //     content: `<div style="padding: 5px;">Marker at ${this.latitude()} ${this.longitude()}</div>`,
        //     closeButton: true,
        //     showPointer: true,
        //     // position: [90.414204, 23.794831],
        //     // position: [0, 0]
        //   }),
        });
    
        this.map.markers.add(marker);
    }

    removeUnwantedText(): void {
        // Manually remove unwanted text elements if they still appear
        setTimeout(() => {
          document.querySelectorAll('.azure-maps-control-container, .azure-maps-attribution, .azure-maps-copyright')
            .forEach(el => {
                console.log(el)
                el.remove()
            });
        }, 500);
      }
}
